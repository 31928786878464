<template>
  <b-list-group flush>
    <b-list-group-item
      v-for="(obj, key) in subscriptionsArray"
      :key="key"
    >
      <b-row v-if="Object.values(obj).length === 3">
        <b-col sm="10">
          <strong>{{ Object.values(obj)[0] }}</strong>
          <br>
          <small>{{ Object.values(obj)[1] }}</small>
        </b-col>
        <b-col sm="2">
          {{ Object.values(obj)[2] }}
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col sm="10">
          <strong>{{ Object.values(obj)[0] }}</strong>
        </b-col>
        <b-col sm="2">
          {{ Object.values(obj)[1] }}
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { BRow, BCol, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  props: {
    subscriptionsArray: {
      type: Array,
      required: true,
      default: null,
    },
  },
}
</script>
