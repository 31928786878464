<template>
  <!-- Subscriptions -->
  <b-row>
    <b-col sm="12">
      <b-list-group>
        <b-list-group-item class="border-right-0 border-left-0 rounded-0">
          <h5 class="text-primary text-uppercase text-center m-0">
            <strong>{{ title }}</strong>
          </h5>
        </b-list-group-item>
      </b-list-group>
    </b-col>

    <!-- Odd Subscription Array -->
    <b-col
      :class="$store.state.appConfig.isRTL ? 'pl-md-0' : 'pr-md-0'"
      sm="12"
      md="6"
    >

      <subscriptions-overview-list-item :subscriptions-array="oddSubscriptionsArray" />

    </b-col>

    <!-- Even Subscriptions Array -->
    <b-col
      :class="$store.state.appConfig.isRTL ? 'pr-md-0' : 'pl-md-0'"
      sm="12"
      md="6"
    >

      <subscriptions-overview-list-item
        :subscriptions-array="evenSubscriptionsArray"
      />

    </b-col>

    <!-- Totals -->
    <b-col
      v-if="totals"
      :class="$store.state.appConfig.isRTL ? 'pl-md-0' : 'pr-md-0'"
      sm="12"
      md="6"
    >
      <b-list-group flush>
        <b-list-group-item variant="success">
          <b-row>
            <b-col sm="10">
              <strong class="text-uppercase">Totals</strong>
            </b-col>
            <b-col sm="2" />
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-col>

    <b-col
      v-if="totals"
      :class="$store.state.appConfig.isRTL ? 'pr-md-0' : 'pl-md-0'"
      sm="12"
      md="6"
    >
      <b-list-group flush>
        <b-list-group-item variant="success">
          <b-row>
            <b-col sm="10" />
            <b-col sm="2">
              <strong>{{ totals }}</strong>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import SubscriptionsOverviewListItem from './SubscriptionsOverviewListItem.vue'

export default {
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,

    SubscriptionsOverviewListItem,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    oddSubscriptionsArray: {
      type: Array,
      required: true,
      default: null,
    },
    evenSubscriptionsArray: {
      type: Array,
      required: true,
      default: null,
    },
    totals: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },

}
</script>
