<template>

  <div>

    <subscriptions-overview-list-filters
      :start-date-filter.sync="startDateFilter"
      :end-date-filter.sync="endDateFilter"
    />

    <!-- Container Card -->
    <b-card
      v-if="subscriptionsOverview && subscriptionsOverview.customers"
      no-body
    >

      <!-- Export -->
      <b-row class="m-2 text-right">
        <b-col>
          <b-dropdown
            v-ripple.400="'rgba(255, 206, 38, 0.15)'"
            text="Export"
            variant="outline-primary"
            size="sm"
            right
          >
            <b-dropdown-item @click="exportPDF">
              PDF
            </b-dropdown-item>

          </b-dropdown>
        </b-col>
      </b-row>

      <b-row
        v-if="subscriptionsOverview && subscriptionsOverview.customers"
        class="mt-2"
      >
        <b-col sm="12">
          <h5 class="text-primary text-uppercase text-center m-0">
            <strong>Total Customers</strong>
          </h5>
          <h1
            class="text-primary text-center mb-0"
            style="font-size: 4rem"
          >
            <strong>{{ subscriptionsOverview.customers }}</strong>
          </h1>
        </b-col>
      </b-row>

      <!-- Subscriptions Overview -->
      <subscriptions-overview-list-items
        v-if="subscriptionsOverview && subscriptionsOverview.length > 0"
        class="mt-3"
        :title="'Subscriptions Overview'"
        :odd-subscriptions-array="oddSubscriptionsOverview"
        :even-subscriptions-array="evenSubscriptionsOverview"
      />

      <!-- Subscriptions Store Types -->
      <!-- <subscriptions-overview-list-items
        v-if="subscriptionsStoreTypes && subscriptionsStoreTypes.length > 0"
        class="mt-4"
        :title="'Subscriptions Store Types'"
        :odd-subscriptions-array="oddSubscriptionsStoreTypes"
        :even-subscriptions-array="evenSubscriptionsStoreTypes"
        :totals="totalSubscriptionsStoreTypes"
      /> -->

      <!-- Subscriptions Departments -->
      <!-- <subscriptions-overview-list-items
        v-if="subscriptionsDepartments && subscriptionsDepartments.length > 0"
        class="mt-4"
        :title="'Subscriptions Departments'"
        :odd-subscriptions-array="oddSubscriptionsDepartments"
        :even-subscriptions-array="evenSubscriptionsDepartments"
        :totals="totalSubscriptionsDepartments"
      /> -->

      <!-- Subscriptions Agent Types -->
      <!-- <subscriptions-overview-list-items
        v-if="subscriptionsAgentTypes && subscriptionsAgentTypes.length > 0"
        class="mt-4"
        :title="'Subscriptions Agent Types'"
        :odd-subscriptions-array="oddSubscriptionsAgentTypes"
        :even-subscriptions-array="evenSubscriptionsAgentTypes"
        :totals="totalSubscriptionsAgentTypes"
      /> -->

      <!-- Subscriptions User Types -->
      <subscriptions-overview-list-items
        v-if="subscriptionsUserTypes && subscriptionsUserTypes.length > 0"
        class="mt-4"
        :title="'Subscriptions User Types'"
        :odd-subscriptions-array="oddSubscriptionsUserTypes"
        :even-subscriptions-array="evenSubscriptionsUserTypes"
        :totals="totalSubscriptionsUserTypes"
      />

      <!-- Subscriptions Packages -->
      <subscriptions-overview-list-items
        v-if="subscriptionsPackages && subscriptionsPackages.length > 0"
        class="mt-4"
        :title="'Subscriptions Packages'"
        :odd-subscriptions-array="oddSubscriptionsPackages"
        :even-subscriptions-array="evenSubscriptionsPackages"
        :totals="totalSubscriptionsPackages"
      />

      <!-- Subscriptions Cities -->
      <subscriptions-overview-list-items
        v-if="subscriptionsCities && subscriptionsCities.length > 0"
        class="mt-4"
        :title="'Subscriptions Cities'"
        :odd-subscriptions-array="oddSubscriptionsCities"
        :even-subscriptions-array="evenSubscriptionsCities"
        :totals="totalSubscriptionsCities"
      />

    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import useSubscriptionsOverviewList from './useSubscriptionsOverviewList'
import subscriptionStoreModule from '../../subscriptionStoreModule'
import SubscriptionsOverviewListFilters from './SubscriptionsOverviewListFilters.vue'
import SubscriptionsOverviewListItems from './SubscriptionsOverviewListItems.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,

    SubscriptionsOverviewListFilters,
    SubscriptionsOverviewListItems,
  },
  directives: {
    Ripple,
  },
  setup() {
    const SUBSCRIPTION_OVERVIEW_STORE_MODULE_NAME = 'app-subscription'

    // Register module
    if (!store.hasModule(SUBSCRIPTION_OVERVIEW_STORE_MODULE_NAME)) store.registerModule(SUBSCRIPTION_OVERVIEW_STORE_MODULE_NAME, subscriptionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUBSCRIPTION_OVERVIEW_STORE_MODULE_NAME)) store.unregisterModule(SUBSCRIPTION_OVERVIEW_STORE_MODULE_NAME)
    })

    const {
      totalSubscriptions,
      subscriptionsOverview,
      oddSubscriptionsOverview,
      evenSubscriptionsOverview,

      subscriptionsStoreTypes,
      oddSubscriptionsStoreTypes,
      evenSubscriptionsStoreTypes,
      totalSubscriptionsStoreTypes,

      subscriptionsDepartments,
      oddSubscriptionsDepartments,
      evenSubscriptionsDepartments,
      totalSubscriptionsDepartments,

      subscriptionsAgentTypes,
      oddSubscriptionsAgentTypes,
      evenSubscriptionsAgentTypes,
      totalSubscriptionsAgentTypes,

      subscriptionsPackages,
      oddSubscriptionsPackages,
      evenSubscriptionsPackages,
      totalSubscriptionsPackages,

      subscriptionsUserTypes,
      oddSubscriptionsUserTypes,
      evenSubscriptionsUserTypes,
      totalSubscriptionsUserTypes,

      subscriptionsCities,
      oddSubscriptionsCities,
      evenSubscriptionsCities,
      totalSubscriptionsCities,

      fetchData,
      exportPDF,

      // Filters
      startDateFilter,
      endDateFilter,
    } = useSubscriptionsOverviewList()

    // Call Functions
    fetchData()

    return {
      totalSubscriptions,
      subscriptionsOverview,
      oddSubscriptionsOverview,
      evenSubscriptionsOverview,

      subscriptionsStoreTypes,
      oddSubscriptionsStoreTypes,
      evenSubscriptionsStoreTypes,
      totalSubscriptionsStoreTypes,

      subscriptionsDepartments,
      oddSubscriptionsDepartments,
      evenSubscriptionsDepartments,
      totalSubscriptionsDepartments,

      subscriptionsAgentTypes,
      oddSubscriptionsAgentTypes,
      evenSubscriptionsAgentTypes,
      totalSubscriptionsAgentTypes,

      subscriptionsPackages,
      oddSubscriptionsPackages,
      evenSubscriptionsPackages,
      totalSubscriptionsPackages,

      subscriptionsUserTypes,
      oddSubscriptionsUserTypes,
      evenSubscriptionsUserTypes,
      totalSubscriptionsUserTypes,

      subscriptionsCities,
      oddSubscriptionsCities,
      evenSubscriptionsCities,
      totalSubscriptionsCities,

      exportPDF,

      // Filters
      startDateFilter,
      endDateFilter,
    }
  },
}
</script>
